import React from 'react';
import { Navigate, NavigateProps, Route } from 'react-router-dom';
import {
  buildPlansExerciseRoute,
  buildPlansRoute,
  buildProgressRoute,
  buildProgressWebviewRoute,
  buildTelehealthJoinRoute,
  buildTelehealthTokenJoinRoute,
} from '../routes';
import { useCurrentParamValues } from './location';

const Reroute: React.FC<NavigateProps> = ({ ...props }) => (
  <Navigate replace={true} {...props} />
);

const RerouteWithParams: React.FC<NavigateProps> = ({ to, ...props }) => {
  const pathWithValues = useCurrentParamValues(to.toString());

  return <Reroute {...props} to={pathWithValues} />;
};

export const LEGACY_ROUTES: JSX.Element[] = [
  <Route path="/profile" element={<Reroute to="/settings/profile" />} />,
  <Route
    path="/appointment/:appointmentId"
    element={<RerouteWithParams to={buildTelehealthJoinRoute()} />}
  />,
  <Route
    path="/appointment"
    element={<Reroute to={buildTelehealthTokenJoinRoute()} />}
  />,
  <Route
    path="/report/hep/:patientId"
    element={<RerouteWithParams to={buildProgressRoute()} />}
  />,
  <Route
    path="/report/hep/webview/:patientId"
    element={<RerouteWithParams to={buildProgressWebviewRoute()} />}
  />,
  <Route
    path="/coach/:prescriptionId"
    element={<RerouteWithParams to={buildPlansRoute()} />}
  />,
  <Route
    path="/coach/:prescriptionId/exercise"
    element={<RerouteWithParams to={buildPlansExerciseRoute()} />}
  />,
  <Route path="/clinician/myPatients" element={<Reroute to="/patients" />} />,
  // All elements must have unique keys when this array gets rendered in a <Routes> component
].map((route, i) => ({ ...route, key: i.toString() }));
